import './App.css';
import CarrosselTextImage from './components/CarroselTextImage.js';

import image1 from './img/imagem-carrosel01.jpeg';
import image2 from './img/imagem-carrosel02.jpeg';
import image3 from './img/imagem-carrosel03.jpeg';
import image4 from './img/imagem-carrosel04.jpeg';
import image5 from './img/imagem-carrosel05.jpeg';
import image6 from './img/imagem-carrosel06.jpeg';
import image7 from './img/imagem-carrosel07.jpeg';
import image8 from './img/imagem-carrosel08.jpeg';
import image9 from './img/imagem-carrosel09.jpeg';
import image10 from './img/imagem-carrosel10.jpeg';
import image11 from './img/imagem-carrosel11.jpeg';
import image12 from './img/imagem-carrosel12.jpeg';

import React, {Component, useEffect, useState, useRef} from 'react';

function App() {
  const [showLandPage, setShowLandPage] = useState(false);

  const handleShowLandPage = () => {
    setShowLandPage(true);
  };

  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchStartX, setTouchStartX] = useState(0);
 

    const images = [
        image1,
        image2,
        image3,
        image4,
        image5,
        image6,
        image7,
        image8,
        image9,
        image10,
        image11,
        image12,
    ];

    const slideWidth = 360;

    const updateSlidePosition = () => {
        const slideTrack = document.querySelector('.slide-track');
        slideTrack.style.transform = `translateX(-${currentIndex * slideWidth}px)`;
    };

    const showSlide = (slideIndex) => {
        setCurrentIndex(slideIndex);
        updateSlidePosition();
    };

    const previousSlide = () => {
        const newIndex = (currentIndex - 1 + images.length) % images.length;
        showSlide(newIndex);
    };

    const nextSlide = () => {
        const newIndex = (currentIndex + 1) % images.length;
        showSlide(newIndex);
    };

    const handleTouchStart = (e) => {
        setTouchStartX(e.touches[0].clientX);
    };

    const handleTouchEnd = (e) => {
        const touchEndX = e.changedTouches[0].clientX;
        const touchDiff = touchEndX - touchStartX;

        if (touchDiff > 50) {
            previousSlide();
        } else if (touchDiff < -50) {
            nextSlide();
        }
    };
  
    const [activeIndex, setActiveIndex] = useState(0);
    const sectionsRef = useRef([]);
    const colors = ['#262523', '#F6F6F6', '#262523', '#F6F6F6'];
  
    useEffect(() => {
      const handleScroll = () => {
        const halfWindowHeight = window.innerHeight / 2;
        const scrollPosition = window.scrollY;
  
        sectionsRef.current.forEach((section, index) => {
          if (!section) return; 
  
          const sectionTop = section.offsetTop;
          const sectionHeight = section.clientHeight;
  
          if (
            scrollPosition >= sectionTop - halfWindowHeight &&
            scrollPosition < sectionTop + sectionHeight - halfWindowHeight
          ) {
            setActiveIndex(index);
          }
        });
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    useEffect(() => {
      const header = document.querySelector('.header');
      const links = document.querySelectorAll('ul li a');
      const logo = document.querySelector('.logo2');
      const logo2 = document.querySelector('.mobile-logo');
      const hamburguer = document.querySelector('.hamburger');
      const ngElements = document.querySelectorAll('.ng-element');
      const teste = document.querySelector('.menu a');
        const teste2 = document.querySelector(' .menu .como-funciona a');
        const teste3 = document.querySelector('.menu .item2 a');
        const teste4 = document.querySelector('.menu .item3 a'); 
        const teste5 = document.querySelector('nav '); 
        const teste6 = document.querySelector('.menu ');

      if (colors[activeIndex] === '#F6F6F6') {
        links.forEach((link) => {
          link.style.color = '#000000';
        });
  
        if (logo) {
          logo.style.color = '#000000';
          logo.style.filter = 'invert(100%)';
        }
        if (header) {
         header.style.color = '#f6f6f6';
          header.style.filter = 'invert(0%)';
        }
        if (teste) {
          teste.style.color = '#fff';
           teste.style.filter = 'invert(100%)';
         }
         if (teste2) {
          teste2.style.color = '#fff';
           teste2.style.filter = 'invert(100%)';
         }
         if (teste3) {
          teste3.style.color = '#fff';
           teste3.style.filter = 'invert(100%)';
         }
         if (teste4) {
          teste4.style.color = '#fff';
           teste4.style.filter = 'invert(100%)';
         }
        if (logo2) {
          logo2.style.color = '#000';
          logo2.style.filter = 'invert(0%)';
        }
        if (teste5) {
          teste5.style.color = '#000000';
          teste5.style.filter = 'invert(100%)';
        }
        if (teste6) {
          teste6.style.color = '#000000';
          teste6.style.filter = 'invert(100%)';
        }
  
        if (hamburguer) {
          hamburguer.style.color = '#000000';
          hamburguer.style.filter = 'invert(100%)';
        }
  
        ngElements.forEach((ngElement) => {
          ngElement.style.color = '#000000';
        });
       
      } else {
        links.forEach((link) => {
          link.style.color = '';
        });
  
        if (logo) {
          logo.style.color = '';
          logo.style.filter = '';
        }
        if (header) {
          header.style.color = '';
          header.style.filter = '';
        }
        if (teste) {
          teste.style.color = '';
          teste.style.filter = '';
        }
        if (teste2) {
          teste2.style.color = '';
          teste2.style.filter = '';
        }
        if (teste3) {
          teste3.style.color = '';
          teste3.style.filter = '';
        }
        if (teste4) {
          teste4.style.color = '';
          teste4.style.filter = '';
        }
        if (teste5) {
          teste5.style.color = '';
          teste5.style.filter = '';
        }
        if (teste6) {
          teste6.style.color = '';
          teste6.style.filter = '';
        }
        
        if (logo2) {
          logo2.style.color = '';
          logo2.style.filter = '';
        }
  
        if (hamburguer) {
          hamburguer.style.color = '';
          hamburguer.style.filter = '';
        }
  
        ngElements.forEach((ngElement) => {
          ngElement.style.color = '';
        });
      }
    }, [activeIndex]);
  

    const [hoverAtivado, setHoverAtivado] = useState(false);

  const handleMouseEnter = () => {
    setHoverAtivado(true);
  };

  const handleMouseLeave = () => {
    setHoverAtivado(false);
  };

  const estilo = {
    color: hoverAtivado ? 'black' : 'white',
  };

  const hamburgerCheckboxRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        hamburgerCheckboxRef.current &&
        !hamburgerCheckboxRef.current.contains(event.target)
      ) {
        hamburgerCheckboxRef.current.checked = false;
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  return (
    <div className="App">
        <body>
          <div className="header" style={{ backgroundColor: colors[activeIndex] }}>
            <div className="logo2">
              <img src="./img/Group.png" alt="Logo"></img>
              <span className="nayara-guimaraes-abreviacao">NG.</span>
            </div>
            <input type="checkbox"
              id="nav_check"
              ref={hamburgerCheckboxRef}
              style={{ display: 'none' }}></input>
            <nav onClick={(event) => event.stopPropagation()}>
              <div className="mobile-logo">
                <img src="./img/Group.png" alt="mobile logo"></img>
                <span className="mobile-nayara-guimaraes-aabrevicao">NG.</span>
              </div>
              <ul className="menu">
                <li className="item1"><a href="#home">Home</a></li>
                <li className="como-funciona"><a href="#funciona">Como Funciona</a></li>
                <li className="item2"><a href="#projeto">Projetos</a></li>
                <li className="item3"><a href="#contatos">Contato</a></li>
              </ul>
            </nav>
            <label htmlFor="nav_check" className="hamburger">
              <div></div>
              <div></div>
              <div></div>
            </label>
          </div>
          <section id="home" className="section" ref={(el) => (sectionsRef.current[0] = el)}>
            <div className="container-grid">
              <div>
                <h1 className="frase">Conforto no seu ambiente acolhedor e charmoso</h1>
                <h3 className="frase2"> A arquitetura é a arte e a ciência de projetar e construir edifícios. Abrangendo a criação de espaços interiores e exteriores, a escolha de materiais e acabamentos, e a consideração de fatores como a segurança, a funcionalidade e a estética.</h3>
              </div>
              <div>
                <img className="img-container2" src="./img/Rectangle 1 (1).png" alt="Uma sala de estar moderna com o tamanho da imagem menor, com um sofá, plantas, cortina, tapete, uma mesa em forma de cuba de madeira com detalhes."></img>
                <img className="img-container" src="./img/Rectangle 1 (1).png" alt="Uma sala de estar moderna, com um sofá, plantas, cortina, tapete, uma mesa em forma de cuba de madeira com detalhes."></img>
              </div>
            </div>
          </section>
          <section id="funciona" className="section" ref={(el) => (sectionsRef.current[1] = el)}>
            <div className="funciona-grid">
              <div>
                <h2 className="planejamento">Planejamento</h2>
                <div className="passo1-legenda1">
                  <p className="passo1">Passo</p> <p className="um">1</p>
                  <p className="legenda-passo1">Vamos agendar uma visita para que eu possa conhecer o imóvel e, juntos, analisarmos o espaço e suas ideias.</p>
                </div>
                <div className="linha-horizontal"></div>
                <div>
                  <h2 className="estimativa">Estimativa</h2>
                  <div className="passo2-legenda2">
                    <p className="passo2">Passo</p> <p className="dois">2</p>
                    <p className="legenda-passo2">Após a visita, vou providenciar um prazo para levantar uma estimativa de valor e elaborar o orçamento, a fim de que você posso esclarecer todas as suas dúvidas.</p>
                  </div>
                  <div className="linha-horizontal2"></div>
                </div>
                <div>
                  <h2 className="construcao">Construção</h2>
                  <div className="passo3-legenda3">
                    <p className="passo3">Passo</p><p className="tres">3</p>
                    <p className="legenda-passo3">Com sua aprovação, enviarei o meu ebook, que detalha de forma aprofundada como funciona o meu trabalho. Assim, poderá conhecer todos os aspectos relevantes antes de prosseguirmos. Em seguida, agendaremos uma reunião para dar início á construção do seu projeto.</p>

                  </div>
                </div>
                <div className="linha-horizontal3"></div>
              </div>
              <div>

                <h2 className="titulo">Entenda como funciona</h2>

                <h3 className="legenda">Vamos planejar o melhor para a sua casa com detalhes.</h3>


                <img src="./img/Rectangle 1.png" alt="Um banheiro moderno, com um espelho redondo na parede e com uma pia do banheiro a cuba cor branca e gabinete de madeira" className="img-legenda"></img>

              </div>
              <div>
                <h2 className="mobile-planejamento">Planejamento</h2>


                <h4 className="mobile-passo1">Passo <h4 className="numero-mobile">1</h4> </h4>
                <h3 className="mobile-legenda1-passo1">Vamos agendar uma visita para que eu possa conhecer o imóvel e, juntos, analisarmos o espaço e suas ideias.</h3>
              </div>
              <div className="mobile-linha-horizontal1"></div>

              <div>
                <h2 className="mobile-estimativa">Estimativa</h2>
                <h4 className="mobile-passo2">Passo <h4 className="numero-mobile">2</h4></h4>
                <h3 className="mobile-legenda-passo2">Após a visita, vou providenciar um prazo para levantar uma estimativa de valor e elaborar o orçamento, a fim de que você posso esclarecer todas as suas dúvidas.</h3>

                <div className="mobile-linha-horizontal2"></div>
              </div>
              <div>
                <h2 className="mobile-construcao">Construção</h2>

                <h4 className="mobile-passo3">Passo <h4 className="numero-mobile">3</h4></h4>
                <h3 className="legenda-mobile-passo3">Com sua aprovação, enviarei o meu ebook, que detalha de forma aprofundada como funciona o meu trabalho. Assim, poderá conhecer todos os aspectos relevantes antes de prosseguirmos. Em seguida, agendaremos uma reunião para dar início á construção do seu projeto.</h3>

              </div>

              <div className="mobile-linha-horizontal3"></div>
            </div>
          </section>
          <section id="projeto" className="section" ref={(el) => (sectionsRef.current[2] = el)}>
            <div>
              <img className="ImageTriangulo" src="./img/Rectangle 3.png" alt="Na tela tem um triángulo escaleno na cor preto"></img>
              <img className="mobile" src="./img/mobile-triangulo.png" alt="Na tela tem um triángulo escaleno na cor preto"></img>
              <h2 className="melhor">O melhor para você</h2>
              <p className="frase-melhor">Em cada projeto reside uma história e singularidades. Meu trabalho consiste em interpretar de forma criativa e técnica, entendendo suas expectativas, necessidades e restrições. Em serviço da Engª. Luana Guimarães CREA: 5069744899-SP  </p>

              <p className="quantidade">+50</p>
              <p className="frase-quantidade">Projetos finalizados</p>
              <p className="quantidade2">+7 anos</p>
              <p className="frase-quantidade2">No mercado</p>
            </div>

            <div className="slide-container" onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
              <div className="slide-track" style={{ width: `${slideWidth * (images.length + 2)}px` }}>
                {images.map((image, index) => (
                  <img key={index} src={image} alt={`Slide ${index}`} />
                ))}
              </div>
              <div className="arrow left-arrow" onClick={previousSlide}></div>
              <div className="arrow right-arrow" onClick={nextSlide}></div>
              <div className="input-control">
                {images.map((_, index) => (
                  <input
                    key={index}
                    className={`carousel-input ${index === currentIndex ? 'active' : ''}`}
                    type="button"
                    onClick={() => showSlide(index)} />
                ))}
              </div>
            </div>

            <div className="frase3">
              <h3>O que os meus clientes estão falando</h3>
            </div>
            <CarrosselTextImage />
          </section>
          <section id="contatos" className="section" ref={(el) => (sectionsRef.current[3] = el)}>

            <h2 className="frase-contato">Entre em contato para construirmos o seu sonho</h2>
            <h3 className="frase-contato2">Quero desenvolver soluções que reflitam sua identidade e personalidade em cada parte do seu empreendimento.</h3>

            <div className="butao" ><a href="https://wa.me/5511992773859?text=Oi%2C+Nayara%21+Estou+entrando+em+contato+para+que+a+gente+possa+realizar+um+sonho" ><p className="enviar" onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              style={estilo}>Clique aqui e envie uma mensagem para o meu whatsapp!</p></a></div>

          </section>


          <footer>
            <div className="footer-flex">
              <img src="./img/favicon.png" className="icon-footer"></img>
              <h2 className="frase-lado-icon">Nayara Guimarães</h2>

            </div>
            <h4 className="funcao">Arquitetura e Urbarnismo</h4>
            <h4 className="endereco">Rua Silveira Martins, 133 - Mogi das Cruzes - SP</h4>
            <h4 className="email-contato">arquitetura@nayaraguimaraes.com</h4>
            <h4 className="telefone">11 9 9277-3859</h4>

            <div className="icons">

              <a href="https://www.instagram.com/nayguimaraes_arq/"><img src="./img/instagram.svg" className="insta" alt="ícone do instagram"></img></a>
              <a href="https://wa.me/5511992773859?text=Oi%2C+Nayara%21+Estou+entrando+em+contato+para+que+a+gente+possa+realizar+um+sonho"><img src="./img/whatsapp.svg" className="icon-whatsapp" alt="ícone do whatsapp"></img></a>
              <a href="https://www.linkedin.com/in/nayara-guimar%C3%A3es/"> <img src="./img/linkedin.svg" className="linkedin" alt="ícone do linkedin"></img></a>
            </div>
            <p className="direitos-reservados">Todos os direitos reservados.</p>
          </footer>
        </body>
      
      
    </div>
  );
}

export default App;


